import { clamp } from "../../../classes/math-util";
import { throwCompileErrorIfReachable } from "../../../classes/misc-util";
import { BreakpointAware } from "../../../classes/breakpoint-aware";
const TOP_BOTTOM_MARGIN = 12;
const PARENT_TOP_BOTTOM_DISTANCE = 12;
const PARENT_LEFT_RIGHT_DISTANCE = 24;
export const POPOVER_PARENT_CLASS = "dx-popover-parent";
function hasTopEnoughSpace(parentBoundings, selfBoundings, options) {
    const top = getTopPosition(options.side, parentBoundings, selfBoundings, TOP_BOTTOM_MARGIN);
    return options.side === "top"
        ? top > TOP_BOTTOM_MARGIN
        : top < document.scrollingElement.clientHeight - TOP_BOTTOM_MARGIN;
}
function getTopPosition(side, parentBoundings, selfBoundings, margin) {
    const functions = {
        top: () => parentBoundings.y - selfBoundings.height - margin,
        bottom: () => parentBoundings.y + parentBoundings.height + selfBoundings.height + margin,
    };
    return functions[side]();
}
function getLeftPosition(alignment, parentBoundings, selfBoundings, margin) {
    const functions = {
        start: () => parentBoundings.x - margin,
        center: () => parentBoundings.x + (parentBoundings.width - selfBoundings.width) * 0.5,
        end: () => parentBoundings.x + parentBoundings.width - selfBoundings.width + margin,
    };
    return functions[alignment]();
}
export function getOppositeSide(side) {
    switch (side) {
        case "top":
            return "bottom";
        case "bottom":
            return "top";
        default:
            throwCompileErrorIfReachable(side);
    }
}
function getInternalPopoverTopPosition(parentBoundings, selfBoundings, options) {
    const scrollingElement = document.scrollingElement;
    const viewportMargin = 16;
    const topPosition = getTopPosition(options.side, parentBoundings, selfBoundings, PARENT_TOP_BOTTOM_DISTANCE);
    let min = Math.max(viewportMargin, parentBoundings.top - PARENT_TOP_BOTTOM_DISTANCE - selfBoundings.height);
    let max = Math.min(parentBoundings.bottom + PARENT_TOP_BOTTOM_DISTANCE, scrollingElement.clientHeight - selfBoundings.height - viewportMargin);
    const minOffset = parentBoundings.top +
        selfBoundings.height -
        scrollingElement.clientHeight -
        PARENT_TOP_BOTTOM_DISTANCE;
    if (minOffset > 0) {
        min = parentBoundings.top - selfBoundings.height - PARENT_TOP_BOTTOM_DISTANCE * 2;
        if (min > max) {
            max = min;
        }
    }
    else {
        const maxOffset = parentBoundings.bottom - selfBoundings.height + PARENT_TOP_BOTTOM_DISTANCE;
        if (maxOffset < 0) {
            max = parentBoundings.bottom + PARENT_TOP_BOTTOM_DISTANCE;
            if (max < min) {
                min = max;
            }
        }
    }
    // We need to set the offset dependent on the current viewport scroll
    const offsetTop = options.withinFixedElement ? 0 : scrollingElement.scrollTop;
    const top = clamp(topPosition, min, max) + offsetTop;
    return {
        position: top,
        arrowOffset: topPosition + offsetTop - top,
        computedSide: options.side,
    };
}
function getInternalPopoverDetachedTopPosition(popoverHeight, options) {
    const positionFunctions = {
        top: () => document.scrollingElement.scrollTop + PARENT_TOP_BOTTOM_DISTANCE,
        bottom: () => document.scrollingElement.scrollTop +
            document.scrollingElement.clientHeight -
            PARENT_TOP_BOTTOM_DISTANCE -
            popoverHeight,
    };
    return {
        position: Math.max(TOP_BOTTOM_MARGIN, positionFunctions[options.side]()),
        arrowOffset: 0,
        computedSide: undefined,
    };
}
function getInternalPopoverDetachedLeftPosition(parentBoundings, selfBoundings, options) {
    return Object.assign(Object.assign({}, getPopoverLeftPosition(parentBoundings, selfBoundings, options)), { computedSide: undefined });
}
export function getPopoverPositions(parentBoundings, selfBoundings, options) {
    const topPositionResult = getPopoverTopPosition(parentBoundings, selfBoundings, options);
    const leftPositionResult = topPositionResult.computedSide
        ? getPopoverLeftPosition(parentBoundings, selfBoundings, options)
        : getInternalPopoverDetachedLeftPosition(parentBoundings, selfBoundings, options);
    return {
        topPositionResult,
        leftPositionResult,
    };
}
function getPopoverTopPosition(parentBoundings, selfBoundings, options) {
    const _getInternalPopoverTopPosition = (side) => getInternalPopoverTopPosition(parentBoundings, selfBoundings, Object.assign(Object.assign({}, options), { side }));
    const _hasTopEnoughSpace = (side) => hasTopEnoughSpace(parentBoundings, selfBoundings, Object.assign(Object.assign({}, options), { side }));
    const enoughSpace = _hasTopEnoughSpace(options.side);
    let finalSide = options.side;
    if (!enoughSpace) {
        finalSide = getOppositeSide(options.side);
        const oppositeSideEnoughSpace = _hasTopEnoughSpace(finalSide);
        if (!oppositeSideEnoughSpace) {
            const position = getInternalPopoverDetachedTopPosition(selfBoundings.height, options);
            position.computedSide = undefined;
            return position;
        }
    }
    return _getInternalPopoverTopPosition(finalSide);
}
function getPopoverLeftPosition(parentBoundings, selfBoundings, options) {
    const scrollingElement = document.scrollingElement;
    const viewportMargin = BreakpointAware.getEffectiveValue({
        mq1: 8,
        mq2: 12,
        mq3: 16,
        mq5: 20,
    });
    const leftPosition = getLeftPosition(options.alignment, parentBoundings, selfBoundings, 0);
    const clampToMin = (num) => Math.max(viewportMargin, num);
    const clampToMax = (num) => Math.min(parentBoundings.right - PARENT_LEFT_RIGHT_DISTANCE, num);
    let min = clampToMin(parentBoundings.left - selfBoundings.width + PARENT_LEFT_RIGHT_DISTANCE);
    let max = clampToMax(scrollingElement.clientWidth - selfBoundings.width - viewportMargin);
    const minOffset = parentBoundings.left +
        selfBoundings.width -
        scrollingElement.clientWidth -
        viewportMargin * 2 -
        PARENT_LEFT_RIGHT_DISTANCE;
    if (minOffset > 0) {
        min = clampToMin(parentBoundings.left - selfBoundings.width + viewportMargin * 2);
        if (min > max) {
            max = min;
        }
    }
    else {
        const maxOffset = parentBoundings.right -
            selfBoundings.width -
            viewportMargin -
            viewportMargin * 2 -
            PARENT_LEFT_RIGHT_DISTANCE * 2;
        if (maxOffset < 0) {
            max = clampToMax(parentBoundings.right - viewportMargin * 2);
            if (max < min) {
                min = max;
            }
        }
    }
    // We need to set the offset dependant on the current viewport scroll
    const offsetLeft = options.withinFixedElement ? 0 : scrollingElement.scrollLeft;
    const left = clamp(leftPosition, min, max) + offsetLeft;
    return {
        position: left,
        arrowOffset: leftPosition + offsetLeft - left,
        computedSide: options.side,
    };
}
export var InternalOpenState;
(function (InternalOpenState) {
    InternalOpenState["OPEN"] = "open";
    InternalOpenState["PRE_OPENING"] = "pre-opening";
    InternalOpenState["OPENING"] = "opening";
    InternalOpenState["CLOSED"] = "closed";
    InternalOpenState["PRE_CLOSING"] = "pre-closing";
    InternalOpenState["CLOSING"] = "closing";
})(InternalOpenState || (InternalOpenState = {}));
