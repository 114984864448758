import { DsSubject, } from "../../classes/multicast-observable";
import { ValueStore } from "../../classes/value-store";
import { ModalScrollHelper } from "../../classes/modal-scroll-helper";
import { readTask, writeTask } from "@stencil/core";
const HEADER_SERVICE_KEY = "ds-modal-service";
export class DialogService {
    constructor() {
        this.openDialogElementsSubject = new DsSubject([]);
    }
    static instance() {
        return ValueStore.instance().getOrPut(HEADER_SERVICE_KEY, () => new DialogService());
    }
    get openDialogs$() {
        return this.openDialogElementsSubject;
    }
    get openDialogs() {
        return this.openDialogElementsSubject.value;
    }
    addDialog(modal, modalScrollableElement) {
        const visibleModals = this.openDialogElementsSubject.value;
        if (visibleModals.includes(modal)) {
            return;
        }
        const scrollHelper = ModalScrollHelper.instance();
        scrollHelper.block(modal, modalScrollableElement);
        const currentOpenDialogs = this.openDialogs;
        const currentVisibleDialog = currentOpenDialogs[currentOpenDialogs.length - 1];
        visibleModals.push(modal);
        this.openDialogElementsSubject.next(visibleModals);
        const getAdditionalZIndex = (modalElement) => modalElement != null
            ? getComputedStyle(modalElement).getPropertyValue("--dx-dialog-additional-z-index")
            : 0;
        readTask(() => {
            const currentVisibleDialogAdditionalZIndex = getAdditionalZIndex(currentVisibleDialog) || 1;
            const newModalAdditionalZIndex = getAdditionalZIndex(modal) || 1;
            this.overrideAdditionalZIndex(modal, Math.max(+newModalAdditionalZIndex, +currentVisibleDialogAdditionalZIndex + 1));
        });
    }
    removeDialog(modal) {
        const visibleModals = this.openDialogElementsSubject.value;
        if (!visibleModals.includes(modal)) {
            return;
        }
        ModalScrollHelper.instance().unblock(modal);
        this.openDialogElementsSubject.next(visibleModals.filter((visibleModal) => visibleModal !== modal));
        this.overrideAdditionalZIndex(modal, undefined);
    }
    overrideAdditionalZIndex(element, num) {
        writeTask(() => {
            if (num != undefined) {
                element.style.setProperty("--dx-dialog-additional-z-index", num.toString());
            }
            else {
                element.style.removeProperty("--dx-dialog-additional-z-index");
            }
        });
    }
}
export function subscribeToOpenDialogs(openDialogsChangeCallback) {
    return DialogService.instance().openDialogs$.subscribe((dialogs) => openDialogsChangeCallback(dialogs));
}
